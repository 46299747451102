<template>
  <div class="healthList">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :immediateCheck="false"
      :finishedText="finishedText"
      :beforeMyFunction="beforeMyFunction"
    >
      <div v-for="(item, index) in listData" :key="index" class="oItem">
        <div class="oItem-head">
          <!-- <div class="oItem-headName">
            <div class="oItem-avatar" v-if="item.avatar">
              <img :src="item.avatar" alt="" />
            </div>
            <div class="oItem-avatar" v-else>
              <img src="@/assets/img/default_avatar.png" alt="" />
            </div>
            <div class="oItem-tl oItem-name">
              {{ item.userName ? formatStrFunc(item.userName) : "" }}
            </div>
          </div> -->
          <div class="oItem-t">
            <span class="oItem-tr oItem-time">{{ item.intime }} </span>
            <span
              :class="[
                'oItem-time',
                'oItem-status',
                actDataClassArrMap[item.auditSts],
              ]"
              >{{ actDataListArrMap[item.auditSts] }}</span
            >
          </div>
        </div>
        <div class="oItem-c" v-if="item.imageUrls">
          <div
            class="item"
            v-for="(items, indexs) in item.imageUrls"
            :key="indexs"
          >
            <img :src="items.ossResize(200, 200)" alt="" />
          </div>
        </div>
        <div class="oItem-b">
          {{ item.proposalContent }}
        </div>
      </div>
    </v-list>
    <div class="dialog" v-if="isDialog">
      <div class="dialog-c">
        <div class="close" @click="toClose">
          <img src="./img/close.png" alt="" />
        </div>
        <div class="title">学习视频</div>
        <div class="title">可获得再次答题的机会</div>
        <div class="btn" @click="lookVideo">查看视频</div>
        <div class="bottom"><img src="./img/back.png" alt="" /></div>
      </div>
    </div>
    <div class="opera" @click="toCamera" v-if="judgeShow()">拍照上传</div>
  </div>
</template>

<script>
import { myHealthUrl, rubTopicInfoUrl } from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
import { getOption, formatStr } from "@/utils/utils.js";
import { actDataListArrMap, actDataClassArrMap } from "./map.js";
var moment = require("moment");
export default {
  name: "healthList",
  data() {
    return {
      isDialog: false,
      actDataListArrMap,
      actDataClassArrMap,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: true,
        name: "运动打卡",
        myUrl: "myWishes",
        noMyOpera: false,
        createText: "去拍照",
      },
      id: "",
      isOne: false,
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
      postData: {
        lastUserAct: {},
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  async mounted() {
    this.getWishList();
    this.getIndexData();
  },
  methods: {
    judgeShow() {
      if (this.postData.actScore == 3) {
        return false;
      } else {
        if (this.postData.todayActTimes >= 2) {
          return false;
        } else {
          if (this.postData.todayActTimes == 0) {
            return true;
          } else {
            if (
              this.postData.lastUserAct &&
              (this.postData.lastUserAct.auditSts == 1 ||
                this.postData.lastUserAct.auditSts == 2)
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    },
    async getIndexData() {
      let res = await this.$axios.get(rubTopicInfoUrl, {
        params: {
          houseId: this.roomId || undefined,
          userId: this.userId,
        },
      });
      if (res.code === 200) {
        this.postData = res.data;
      } else {
      }
    },
    lookVideo() {
      this.$router.push({
        name: "studyVideo",
        query: { oFrom: "isCamera" },
      });
    },
    toClose() {
      this.isDialog = false;
    },
    toCamera() {
      if (this.postData.todayActTimes == 0) {
        this.$router.push({
          name: "rubbishSortCamera",
        });
      } else {
        if (this.postData.actScore != 1) {
          this.$router.push({
            name: "rubbishSortCamera",
          });
        } else {
          this.isDialog = true;
        }
      }
    },
    beforeMyFunction() {
      if (this.isOne) {
        this.$toast("今日已打卡");
      }
    },

    formatStrFunc(str) {
      if (str) {
        return formatStr(str);
      }
    },

    onLoad() {
      this.getWishList();
    },
    getWishList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        houseId: this.roomId || undefined,
        topicId: this.id,
      };
      this.$axios.get(`${myHealthUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          // if (res.data.hasTodayRecord) {
          //   this.isOne = true;
          //   this.commonOperaParams.noMyOpera = true;
          // }
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.imageUrls) {
              item.imageUrls = item.imageUrls.split(",");
              // item.healthPic.forEach((items, index) => {
              //   // console.log(items, 88988);
              //   items.ossResize(200, 200);
              // });
            }
            this.listData.push(item);
          });
          this.$forceUpdate();
        } else {
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "healthDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.healthList {
  min-height: 100vh;
  background: #f7f7f7;
  .oItem {
    background: #fff;
    padding: 40px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 30px;
    .oItem-head {
      //   display: flex;
      //   justify-content: space-between;
      font-weight: bold;
      margin-bottom: 10px;
      align-items: center;
    }
    .oItem-headName {
      display: flex;
      align-items: center;
    }

    .oItem-t {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .oItem-avatar {
      width: 80px;
      height: 80px;
      /*border-radius:30px;*/
      /*border: 0.5px solid #999;*/
    }
    .oItem-avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      /*border: 0.5px solid #999;*/
    }
    .oItem-name {
      margin-left: 15px;
      font-size: 32px;
      font-weight: bold;
    }
    .oItem-time {
      font-size: 28px !important;
      font-weight: normal !important;
      color: #999;
    }
    .oItem-status {
      color: #178af7;
    }
    .caraStatus0 {
      color: #008cd6;
    }
    .caraStatus1 {
      color: #e50012;
    }
    .caraStatus2 {
      color: #f8cc5c;
    }
    .caraStatus3 {
      color: #40a738;
    }
    .out,
    .reback {
      color: #999999;
    }
    .oItem-c {
      display: flex;
      justify-content: space-between;
      .item {
        width: 200px;
        height: 200px;
        img {
          width: 100%;
          height: 100%;
        }
        border-radius: 10px;
      }
    }
    .oItem-b {
      font-size: 32px;
      width: calc(100% - 60px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 30px;
      span {
        color: salmon;
      }
    }
  }
  .opera {
    position: fixed;
    left: 0;
    z-index: 99;
    bottom: 0;
    width: 100%;
    height: 96px;
    background: #007eff;
    text-align: center;
    font-size: 32px;
    line-height: 96px;
    color: #fff;
  }
  .dialog {
    background: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    .dialog-c {
      width: 646px;
      height: 494px;
      background: #ffffff;
      border-radius: 14px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-top: 108px;
      box-sizing: border-box;
      .close {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 30px;
        right: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .video {
        width: 100%;
        height: 300px;
        video {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 50px;
      }
      .btn {
        width: 246px;
        height: 80px;
        background: #2e5da2;
        border-radius: 40px;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        color: #ffffff;
        line-height: 80px;
        margin: 52px auto 40px;
      }
      .bottom {
        width: 574px;
        height: 106px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
